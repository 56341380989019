import React from "react";
import Layout from "../../components/layout";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import BankIDLogo from "../../components/BankIDLogo";
import PasswordLogin from "../../components/account/PasswordLogin";
import BankIDLogin from "../../components/account/BankIDLogin";
import LoadingCirrus from "../../components/LoadingCirrus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openIDConnectIssuers } from "../../scripts/oidc-issuers";

const Login = () => {
  const [useBankID, setUseBankID] = React.useState(false);
  const [isCirrusLoading, setIsCirrusLoading] = React.useState(false);
  const location = useLocation();

  if (isCirrusLoading) return <LoadingCirrus />;

  if (useBankID)
    return (
      <Layout>
        <div id="login" className="container narrow">
          <BankIDLogin onComplete={() => setIsCirrusLoading(true)} />
          <div className="is-flex">
            <Link
              className="is-link has-text-weight-bold is-size-6 mx-auto"
              onClick={() => setUseBankID(false)}
              to={`/account/login${location.search}`}
            >
              <FormattedMessage id="useAnotherLogin" />
            </Link>
          </div>
        </div>
      </Layout>
    );
  return (
    <Layout>
      <div
        id="login"
        className="container narrow is-flex is-flex-direction-column"
      >
        <h1 className="title is-3 mb-6">
          <FormattedMessage id="welcome" />
        </h1>
        <PasswordLogin onComplete={() => setIsCirrusLoading(true)} />
        <div className="my-auto" />
        <div className="alt-login-buttons mt-4">
          <button
            className="button is-primary is-medium"
            onClick={() => setUseBankID(true)}
          >
            <span className="icon">
              <BankIDLogo version="white" noPadding />
            </span>
            <span>
              <FormattedMessage id={"signInWith"} values={{ name: "BankID" }} />
            </span>
          </button>
          {openIDConnectIssuers.map(({ issuer, name, icon }) => (
            <button
              onClick={() =>
                (window.location.href = `/oidc/sign-in/${encodeURIComponent(
                  issuer,
                )}${location.search}`)
              }
              className="button is-primary is-medium"
              key={issuer}
            >
              <span className="icon">
                <FontAwesomeIcon icon={icon} />
              </span>
              <span>
                <FormattedMessage id={"signInWith"} values={{ name }} />
              </span>
            </button>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Login;
