import React, { useState } from "react";
import useLayoutEffect from "../../scripts/use-isomorphic-layout-effect";
import FormWrapper from "../../components/form-wrapper";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { checkAccessToken, login } from "../../scripts/auth-helper";
import { FormattedMessage, useIntl } from "react-intl";
import { captureException } from "@sentry/core";

interface Props {
  onComplete?: () => void;
}

const Login = (p: Props) => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const intl = useIntl();

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const loginRes = await login(email, password);
      if (loginRes === "Invalid email") {
        setErrorMessage("invalidEmail");
      } else if (loginRes === "Invalid password") {
        setErrorMessage("wrongPassword");
      } else {
        p.onComplete?.();
      }
    } catch (e) {
      captureException(e);
      console.error("Unable to login():", e);
      setErrorMessage("generalError");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _email = e.target.value;
    setEmail(_email);
    setErrorMessage(null);
  };

  const handlePasswordUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _password = e.target.value;
    setPassword(_password);
    setErrorMessage(null);
  };

  useLayoutEffect(() => {
    (async () => {
      await checkAccessToken;
    })();
    React.startTransition(() =>
      setSubmitDisabled(email.length === 0 || password.length === 0),
    );
  });

  return (
    <>
      {errorMessage && (
        <div className="notification is-danger is-light has-text-weight-bold has-text-centered">
          <FormattedMessage id={errorMessage} />
        </div>
      )}
      <FormWrapper>
        <form className="block" onSubmit={handleLogin}>
          <div className="field">
            <div className="control">
              <input
                className="login-input"
                type="email"
                placeholder={intl.formatMessage({ id: "emailAddress" })}
                value={email}
                onChange={handleEmailUpdate}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="login-input"
                type="password"
                placeholder={intl.formatMessage({ id: "enterPassword" })}
                value={password}
                onChange={handlePasswordUpdate}
              />
            </div>
          </div>
          <input
            className={
              "login-button is-primary is-medium " +
              (loading ? "is-loading" : "")
            }
            disabled={submitDisabled}
            type="submit"
            value={intl.formatMessage({ id: "logIn" })}
          />
        </form>
        <Link
          className="is-link has-text-weight-bold is-size-6"
          to={`/account/request-password-reset${location.search}`}
        >
          <FormattedMessage id="forgotPassword" />
        </Link>
      </FormWrapper>
    </>
  );
};

export default Login;
